import { eth, utils } from "web3";

export class Wallet {
  privateKey: string;
  address: string;

  constructor(mail: string) {
    const { accounts } = eth;
    this.privateKey = utils.keccak256(mail);
    this.address = accounts.privateKeyToAddress(this.privateKey);
  }

  sign(hash: string) {
    const { accounts } = eth;
    const { signature } = accounts.sign(hash, this.privateKey);
    return signature;
  }

  static getAddress(mail: string) {
    const { accounts } = eth;
    return accounts.privateKeyToAddress(utils.keccak256(mail));
  }
}
