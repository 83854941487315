import httpClient from "./axios";
import { PutDocTable } from "./type";

export const putDocument = async (putDoc: PutDocTable) => {
  console.log(putDoc);
  const { data } = (await httpClient
    .post("put", putDoc)
    .catch(console.log)) as any;
  return data;
};
