import * as React from "react";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { MailStatus, isValidMailAddress } from "../utils/mail";

export interface MailProps {
  mailAddress: string;
  setMailAddress: (mail: string) => void;
  mailAddressIsValid: MailStatus;
  setMailAddressIsValid: (MailStatus: MailStatus) => void;
  file: string;
  setIsReady: (isReady: boolean) => void;
}

export function Mail({
  mailAddress,
  setMailAddress,
  mailAddressIsValid,
  setMailAddressIsValid,
  file,
  setIsReady,
}: MailProps) {
  const modifyMailAddress = ({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = target;
    setMailAddress(value);
    const mailIsValid = isValidMailAddress(value);
    setMailAddressIsValid(mailIsValid ? MailStatus.Valid : MailStatus.Invalid);
    if (value === "") {
      setMailAddressIsValid(MailStatus.None);
    }
    if (mailIsValid) {
      if (file) setIsReady(true);
      localStorage.setItem("account", value);
    }
  };

  return (
    <FormControl
      label="メールアドレス"
      caption="メールアドレスを入力してください"
      error={
        mailAddressIsValid === MailStatus.Invalid
          ? "メールアドレスが不正です"
          : ""
      }
      positive={
        mailAddressIsValid === MailStatus.Valid
          ? "利用可能なメールアドレスです"
          : ""
      }
    >
      <Input
        placeholder="メルマガ配信や会員登録は行われません"
        value={mailAddress}
        onChange={(event) => modifyMailAddress(event)}
        error={mailAddressIsValid === MailStatus.Invalid}
        positive={mailAddressIsValid === MailStatus.Valid}
        clearable
      />
    </FormControl>
  );
}
