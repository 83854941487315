import * as React from "react";
import { FormControl } from "baseui/form-control";
import { FileUploader } from "baseui/file-uploader";
import { imgExt } from "../utils/constant";
import { MailStatus } from "../utils/mail";

export interface FileProps {
  file: string;
  setFile: (file: string) => void;
  mailAddressIsValid: MailStatus;
  setIsReady: (isReady: boolean) => void;
}

export function File({
  file,
  setFile,
  mailAddressIsValid,
  setIsReady,
}: FileProps) {
  const [fakeProgress, setFakeProgress] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);
  const [tempFile, setTempFile] = React.useState("");
  const [isImg, setIsImg] = React.useState(false);

  const setFileData = (files: File[]) => {
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (target) {
        setTempFile(String(target.result));
      }
    };
    const file = files[0];
    setIsImg(imgExt.includes(file.name.split(".")[1]));
    setIsActive(true);
    reader.readAsDataURL(file);
    if (mailAddressIsValid === MailStatus.Valid) setIsReady(true);
  };

  const stopFakeProgress = () => {
    setIsActive(false);
    setFakeProgress(0);
  };

  useInterval(
    () => {
      if (fakeProgress >= 100) {
        stopFakeProgress();
        setFile(tempFile);
        setTempFile("");
      } else {
        const add = Math.floor(Math.random() * 10);
        const nextProgress = fakeProgress + add;
        setFakeProgress(nextProgress <= 100 ? nextProgress : 100);
      }
    },
    isActive ? 50 : null,
  );

  return (
    <FormControl
      label="ファイル"
      caption="ファイルをアップロードしてください"
      positive={file ? "利用可能なファイル形式です" : ""}
    >
      {file ? (
        <img
          src={isImg ? file : "/images/doc.png"}
          style={{ height: "15vh" }}
          alt="uploaded_file"
        />
      ) : (
        <FileUploader
          onCancel={stopFakeProgress}
          onDrop={setFileData}
          progressAmount={fakeProgress}
          progressMessage={
            fakeProgress
              ? `ファイルをアップロードしています... ${fakeProgress}% / 100%`
              : ""
          }
        />
      )}
    </FormControl>
  );
}

function useInterval(callback: any, delay: number | null) {
  const savedCallback = React.useRef(() => {});

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect((): any => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
