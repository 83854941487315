import { utils } from "web3";
import { StyledTitle, StyledBody, StyledAction } from "baseui/card";
import { Button } from "baseui/button";
import { Mail, MailProps } from "./components/Mail";
import { File, FileProps } from "./components/File";
import { Wallet } from "./utils/wallet";
import { putDocument } from "./utils/document";
import { PutDocTable, Suite } from "./utils/type";
import { useState } from "react";

interface IssueCertificateProps extends MailProps, FileProps {
  isReady: boolean;
  setIsReady: (isReady: boolean) => void;
}

export function IssueCertificate(issueCertificateProps: IssueCertificateProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const issueCertificate = async () => {
    setIsLoading(true);
    const { file, mailAddress } = issueCertificateProps;
    const { isReady } = issueCertificateProps;
    if (isReady) {
      const wallet = new Wallet(mailAddress);
      const hash = utils.keccak256(file);
      const signature = wallet.sign(hash);

      const request: PutDocTable = {
        tableName: "doc",
        suite: Suite.ECDSA,
        hash,
        signature,
        mailAddress,
      };

      const res = await putDocument(request);
      console.log(res);
      setIsDone(true);
      setIsLoading(true);
    }
  };

  return (
    <>
      <StyledTitle>証明書の発行</StyledTitle>
      <br />
      <StyledBody>
        電子ファイルに署名を行いブロックチェーン上に登録することで契約締結や原本証明を行います。
        <br />
        メールアドレス宛に証明書が送信され、オンライン上で誰でも証明を確認することができます。
      </StyledBody>
      <br />
      {isDone ? (
        <StyledBody>
          証明書の発行を申請しました。
          <br />
          ブロックチェーン上に記録されると{" "}
          <b>{issueCertificateProps.mailAddress}</b> 宛に証明書が送信されます。
          <br />
          証明書と電子ファイルを用いてこちらから検証できます。
        </StyledBody>
      ) : (
        <>
          <Mail {...issueCertificateProps} />
          <br />
          <File {...issueCertificateProps} />
          <br />
          <StyledAction>
            <Button
              overrides={{ BaseButton: { style: { width: "100%" } } }}
              disabled={!issueCertificateProps.isReady}
              onClick={issueCertificate}
              isLoading={isLoading}
            >
              証明書の発行
            </Button>
          </StyledAction>
        </>
      )}
    </>
  );
}
