export enum Suite {
  ECDSA = 1,
}

export interface PutDocTable {
  tableName: string;
  suite: Suite;
  hash: string;
  signature: string;
  mailAddress: string;
}
