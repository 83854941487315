import * as React from "react";
import { useStyletron } from "baseui";
import { Layer } from "baseui/layer";
import { AppNavBar, setItemActive, NavItem } from "baseui/app-nav-bar";
import { IssueCertificate } from "./IssueCertificate";
import { MailStatus } from "./utils/mail";
import { tabItems, userItems } from "./utils/constant";
import { Wallet } from "./utils/wallet";

function App() {
  const [css] = useStyletron();
  const [mailAddress, setMailAddress] = React.useState("");
  const [mailAddressIsValid, setMailAddressIsValid] = React.useState(
    MailStatus.None,
  );
  const [file, setFile] = React.useState("");
  const [isReady, setIsReady] = React.useState(false);
  const [currentLabel, setCurrentLabel] = React.useState("証明書発行");
  const [mainItems, setMainItems] = React.useState<NavItem[]>(tabItems);

  React.useEffect(() => {
    setMainItems((prev) => setItemActive(prev, prev[0]));
    const account = localStorage.getItem("account");
    if (account) {
      setMailAddress(account);
      setMailAddressIsValid(MailStatus.Valid);
    }
  }, []);

  const handleMainItemSelect = (item: NavItem) => {
    setCurrentLabel(item.label);
    setMainItems((prev) => setItemActive(prev, item));
  };

  return (
    <React.Fragment>
      <Layer>
        <div
          className={css({
            boxSizing: "border-box",
            width: "100vw",
            position: "fixed",
            top: "0",
            left: "0",
          })}
        >
          <AppNavBar
            title="DX Hyve Demo"
            mainItems={mainItems}
            userItems={userItems}
            onMainItemSelect={handleMainItemSelect}
            onUserItemSelect={(item) => console.log("user", item)}
            username={
              mailAddressIsValid === MailStatus.Valid
                ? mailAddress
                : "Unknown User"
            }
            usernameSubtitle={
              mailAddressIsValid === MailStatus.Valid
                ? `${Wallet.getAddress(mailAddress).slice(0, 7)}...${Wallet.getAddress(mailAddress).slice(37, 42)}`
                : ""
            }
            userImgUrl=""
          />
          <div
            className={css({
              width: "60vw",
              marginTop: "1vh",
              marginLeft: "auto",
              marginRight: "auto",
            })}
          >
            {(currentLabel === "証明書発行" ||
              currentLabel === "電子書類証明") && (
              <IssueCertificate
                mailAddress={mailAddress}
                setMailAddress={setMailAddress}
                mailAddressIsValid={mailAddressIsValid}
                setMailAddressIsValid={setMailAddressIsValid}
                file={file}
                setFile={setFile}
                isReady={isReady}
                setIsReady={setIsReady}
              />
            )}
          </div>
        </div>
      </Layer>
    </React.Fragment>
  );
}

export default App;
