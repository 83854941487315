export enum MailStatus {
  None,
  Valid,
  Invalid,
}

const regex =
  /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

export const isValidMailAddress = (mail: string) => regex.test(mail);
