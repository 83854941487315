import { Upload } from "baseui/icon";
import { NavItem } from "baseui/app-nav-bar";

export const tabItems: NavItem[] = [
  {
    icon: Upload,
    label: "証明書発行",
    children: [
      { icon: Upload, label: "電子書類証明" },
      { icon: Upload, label: "NFT発行" },
    ],
  },
  {
    icon: Upload,
    label: "証明書検証",
    children: [
      { icon: Upload, label: "PDF検証" },
      { icon: Upload, label: "NFT検証" },
    ],
  },
  { icon: Upload, label: "DX Hyveについて" },
];

export const userItems = [
  { icon: Upload, label: "Account item1" },
  { icon: Upload, label: "Account item2" },
  { icon: Upload, label: "Account item3" },
  { icon: Upload, label: "Account item4" },
];

export const imgExt = [
  "jpg",
  "jpeg",
  "jpe",
  "jfif",
  "pjpeg",
  "pjp",
  "png",
  "gif",
  "tiff",
  "tif",
];
